import {
  Collapse,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
// import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const SidebarItem = ({
  item,
  selectedIndex,
  setSelectedIndex,
  closeDrawer,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <ListItemButton
        // sx={{ color: "#063970", fontSize: 18 }}
        component={Link}
        to={item.path}
        selected={selectedIndex === item.title}
        onClick={() => {
          setOpen(!open);
          setSelectedIndex(item.title);
          !item.childrens && closeDrawer();
        }}
      >
        <ListItemIcon> {item.icon} </ListItemIcon>
        <ListItemText
          primary={item.title}

          // primary={item.childrens ? item.title : "mohamed"}
          //primary={item.title}
          // sx={{ color: "#1565c0", fontStyle: "italic" }}
        />
        {item.childrens && open ? (
          <ExpandMore />
        ) : item.childrens ? (
          <ExpandLess />
        ) : null}
      </ListItemButton>

      {item.childrens && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {item.childrens.map((child, index) => (
            <ListItemButton
              key={index}
              component={Link}
              to={child.path}
              sx={{ pl: 5 }}
              selected={selectedIndex === child.title}
              //onClick={() => setSelectedIndex(child.title)}
              onClick={() => {
                setSelectedIndex(child.title);
                closeDrawer();
              }}
            >
              <ListItemIcon>
                {child.icon ? child.icon : <StarBorder />}
              </ListItemIcon>
              <ListItemText primary={child.title} />
            </ListItemButton>
          ))}
        </Collapse>
      )}
      <Divider />
    </>
  );
};

export default SidebarItem;
