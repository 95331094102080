import React, { Fragment } from "react";

import { Box, MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid2 version 2
//import useAuth from "../../auth/UseAuth";

const StaffFormTwo = (props) => {
  const { register, errors, auth } = props;
  //const { auth } = useAuth();

  return (
    <Fragment>
      <Box px={1}>
        <Grid container spacing={1}>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="Country"
              fullWidth
              size="small"
              margin="dense"
              {...register("country")}
              error={errors?.country ? true : false}
              helperText={errors?.country?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="State"
              fullWidth
              size="small"
              margin="dense"
              {...register("state_name")}
              error={errors?.state_name ? true : false}
              helperText={errors?.state_name?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="Region"
              fullWidth
              size="small"
              margin="dense"
              {...register("region_name")}
              error={errors?.region_name ? true : false}
              helperText={errors?.region_name?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="District"
              fullWidth
              size="small"
              margin="dense"
              {...register("district")}
              error={errors?.district ? true : false}
              helperText={errors?.district?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="Village"
              fullWidth
              size="small"
              margin="dense"
              {...register("village")}
              error={errors?.village ? true : false}
              helperText={errors?.village?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="Nationality"
              fullWidth
              size="small"
              margin="dense"
              {...register("nationality")}
              error={errors?.nationality ? true : false}
              helperText={errors?.nationality?.message}
            />
          </Grid>

          <Grid xs={12} sm={6}>
            <TextField
              label="Disablity Status"
              select
              defaultValue=""
              fullWidth
              size="small"
              //color="primary"
              //margin="dense"
              {...register("disability")}
              error={errors?.disability ? true : false}
              helperText={errors?.disability?.message}
            >
              <MenuItem value="No Disability">No Disability </MenuItem>
              <MenuItem value="Visual">Visual</MenuItem>
              <MenuItem value="Hearing">Hearing</MenuItem>
              <MenuItem value="Hands">Hands </MenuItem>
              <MenuItem value="Mental">Mental</MenuItem>
              <MenuItem value="Limbs (Movement)">Limbs (Movement) </MenuItem>
              <MenuItem value="Gift/talented">Gift/talented"</MenuItem>
              <MenuItem value="Others">Others </MenuItem>
            </TextField>
          </Grid>

          {/* <Grid  xs={12} sm={12} sx={{ mt: 1 }}>
            <Divider>
              <Chip label="School and Authrization" />
            </Divider>
          </Grid> */}

          <Grid xs={12} sm={6}>
            <TextField
              required
              select
              label="Active "
              fullWidth
              size="small"
              margin="dense"
              defaultValue="1"
              {...register("active")}
              error={errors?.active ? true : false}
              helperText={errors?.active?.message}
            >
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No </MenuItem>
            </TextField>
          </Grid>
          <Grid xs={12} sm={12}>
            <TextField
              required
              label="Authorized Name"
              fullWidth
              defaultValue={auth?.user.toUpperCase()}
              value={auth?.user}
              size="small"
              margin="dense"
              InputProps={{
                readOnly: true,
              }}
              {...register("authorized_name")}
              error={errors?.authorized_name ? true : false}
              helperText={errors?.authorized_name?.message}
            />
          </Grid>

          <Grid xs={12} sm={12}>
            <TextField
              required
              label="School ID"
              fullWidth
              size="small"
              margin="dense"
              {...register("school_id")}
              error={errors?.school_id ? true : false}
              helperText={errors?.school_id?.message}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default StaffFormTwo;
