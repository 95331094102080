import React, { Fragment } from "react";

import { Box, MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid2 version 2

const TeacherGuarantor = (props) => {
  const { register, errors } = props;
  return (
    <Fragment>
      <Box px={1}>
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <Grid xs={12} sm={12}>
              <TextField
                required
                label="Guarantor Full Name"
                fullWidth
                size="small"
                margin="dense"
                {...register("guarantor_name")}
                error={errors?.guarantor_name ? true : false}
                helperText={errors?.guarantor_name?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Guarantor Email"
                fullWidth
                size="small"
                margin="dense"
                {...register("guarantor_email")}
                error={errors?.guarantor_email ? true : false}
                helperText={errors?.guarantor_email?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Guarantor Phone"
                fullWidth
                size="small"
                margin="dense"
                {...register("guarantor_phone")}
                error={errors?.guarantor_phone ? true : false}
                helperText={errors?.guarantor_phone?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Guarantor Phone 2"
                fullWidth
                size="small"
                margin="dense"
                {...register("guarantor_phone2")}
                error={errors?.guarantor_phone2 ? true : false}
                helperText={errors?.guarantor_phone2?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Relationship"
                select
                defaultValue=""
                fullWidth
                size="small"
                color="primary"
                //margin="dense"
                {...register("relationship")}
                error={errors?.relationship ? true : false}
                helperText={errors?.relationship?.message}
              >
                <MenuItem value="father">Father</MenuItem>
                <MenuItem value="mother">Mother</MenuItem>
                <MenuItem value="sibling">Sibling </MenuItem>
                <MenuItem value="ancle">Ancle </MenuItem>
                <MenuItem value="aunt">Aunt </MenuItem>
                <MenuItem value="friend">Friend </MenuItem>
                <MenuItem value="others">Others </MenuItem>
              </TextField>
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Staff Mother Phone"
                fullWidth
                size="small"
                margin="dense"
                {...register("mother_phone")}
                error={errors?.mother_phone ? true : false}
                helperText={errors?.mother_phone?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Staff Father Phone"
                fullWidth
                size="small"
                margin="dense"
                {...register("father_phone")}
                error={errors?.father_phone ? true : false}
                helperText={errors?.father_phone?.message}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default TeacherGuarantor;
