import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  PieChart,
  Pie,
  AreaChart,
  Area,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "School A",
    boys: 4000,
    girls: 2400,
    amt: 2400,
  },
  {
    name: "School B",
    boys: 3000,
    girls: 1398,
    amt: 2210,
  },
  {
    name: "School C",
    boys: 2000,
    girls: 9800,
    amt: 2290,
  },
  {
    name: "School D",
    boys: 2780,
    girls: 3908,
    amt: 2000,
  },
  {
    name: "School E",
    boys: 1890,
    girls: 4800,
    amt: 2181,
  },
  {
    name: "School F",
    boys: 2390,
    girls: 3800,
    amt: 2500,
  },
  {
    name: "School G",
    boys: 3490,
    girls: 4300,
    amt: 2100,
  },
];

const data2 = [
  { name: "2017", react: 32, angular: 37, vue: 60 },
  { name: "2018", react: 42, angular: 42, vue: 54 },
  { name: "2019", react: 51, angular: 41, vue: 54 },
  { name: "2020", react: 60, angular: 37, vue: 28 },
  { name: "2021", react: 51, angular: 31, vue: 27 },
  { name: "2022", react: 95, angular: 44, vue: 49 },
];
const data01 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const data02 = [
  { name: "A1", value: 100 },
  { name: "A2", value: 300 },
  { name: "B1", value: 100 },
  { name: "B2", value: 80 },
  { name: "B3", value: 40 },
  { name: "B4", value: 30 },
  { name: "B5", value: 50 },
  { name: "C1", value: 100 },
  { name: "C2", value: 200 },
  { name: "D1", value: 150 },
  { name: "D2", value: 50 },
];

export const LineChartExample = () => {
  return (
    <ResponsiveContainer width={"100%"} height={300} min-width={300}>
      <LineChart
        //width={375}
        //height={300}
        data={data}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="girls"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="boys" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};

// 👇️ named export
export const LineChart2Example = () => {
  return (
    <ResponsiveContainer width={"100%"} height={300} min-width={300}>
      <LineChart
        //width={375}
        //height={300}
        data={data2}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Line
          type="monotone"
          dataKey="react"
          stroke="#2196F3"
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="angular"
          stroke="#F44236"
          strokeWidth={3}
        />
        <Line type="monotone" dataKey="vue" stroke="#FFCA29" strokeWidth={3} />
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const BarChartExample = () => {
  return (
    <ResponsiveContainer width={"100%"} height={300} min-width={300}>
      <BarChart
        //width={375}
        //height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="girls" fill="#8884d8" />
        <Bar dataKey="boys" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const PieChartExample = () => {
  return (
    <ResponsiveContainer width={"100%"} height={300} min-width={300}>
      <PieChart>
        <Pie
          data={data01}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={60}
          fill="#8884d8"
        />
        <Pie
          data={data02}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={90}
          fill="#82ca9d"
          label
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export const AreaChartExample = () => {
  return (
    <ResponsiveContainer width={"100%"} height={300} min-width={300}>
      <AreaChart
        //width={375}
        //height={300}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="boys" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
