import React, { useState } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { createTheme } from "@mui/material/styles";

//import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import TeacherFormOne from "./TeacherFormOne";
import TeacherFormTwo from "./TeacherFormTwo";
import validationSchema from "./TeacherValidationSchema";
import TeacherGuarantor from "./TeacherGuarantor";
import TeacherFormThree from "./TeacherFormThree";
import Review from "./Review";
import useAuth from "../../auth/UseAuth";

const steps = ["Information", "Locaion", "Guarantor", "Review"];

//const theme = createTheme();

export default function TeacherFormSteps() {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  const { auth } = useAuth();

  const currentValidationSchema = validationSchema[activeStep];

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(currentValidationSchema),
  });

  const handleNext = (data) => {
    setFormValues({
      ...formValues,
      ...data,
    });

    setActiveStep(activeStep + 1);
    // console.log(activeStep);
  };

  const handleBack = (data) => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <TeacherFormOne register={register} errors={errors} watch={watch} />
        );
      case 1:
        return (
          <TeacherFormTwo register={register} errors={errors} auth={auth} />
        );
      case 2:
        return <TeacherFormThree register={register} errors={errors} />;
      case 3:
        return <TeacherGuarantor register={register} errors={errors} />;
      case 4:
        return <Review formValues={formValues} />;
      default: {
        // <React.Fragment>
        //   <Typography variant="h5" gutterBottom>
        //     Thank you for your order.
        //   </Typography>
        //   <Typography variant="subtitle1">
        //     Your order number is #2001539. We have emailed your order
        //     confirmation, and will send you an update when your order has
        //     shipped.
        //   </Typography>
        // </React.Fragment>;
        throw new Error("Unknown step");
      }
    }
  }

  return (
    <>
      <Box
        display={"flex"}
        //maxWidth="sm"
        margin="auto"
        boxShadow={"5px 5px 15px "}
        sx={{
          mt: { xs: 4, sm: 10 },
          mb: "auto",
          borderRadius: 3,
          maxWidth: "sm",
        }}
      >
        <Paper
          variant="outlined"
          //maxWidth="sm"
          sx={{
            p: 2,
            border: 2,
            borderColor: "blueviolet",
            borderRadius: 3,
            width: "100%",
            maxWidth: "sm",
            bgcolor: "#fbe9e7",
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            sx={{ pt: 3, pb: 3 }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography component="h1" variant="h5" align="center">
            {/* <Avatar sx={{ bgcolor: "secondary.main" }}>
              <FamilyRestroomIcon />
            </Avatar> */}
            Teacher Form Part {activeStep + 1}
          </Typography>
          <Box
            sx={{
              border: 2,
              borderColor: "blueviolet",
              borderRadius: 3,
              px: 2,
              py: 2,
              mx: 2,
              bgcolor: "white",
            }}
          >
            {activeStep !== steps.length && getStepContent(activeStep)}
            {activeStep === steps.length && (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #2001539. We have emailed your order
                  confirmation, and will send you an update when your order has
                  shipped.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleBack}
                  sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                >
                  Back {activeStep}
                </Button>
              </React.Fragment>
            )}
          </Box>
          <React.Fragment>
            {activeStep !== steps.length && (
              <React.Fragment>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {activeStep !== 0 && (
                    <Button
                      variant="contained"
                      onClick={handleBack}
                      sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                    >
                      Back {activeStep}
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleNext)}
                      sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                    >
                      Place order
                    </Button>
                  )}
                  {activeStep !== steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleNext)}
                      sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
          {/* </Box> */}
        </Paper>
      </Box>
    </>
  );
}
