import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <>
      {/* <section>
      <h1>Unauthorized</h1>
      <br />
      <p>You do not have access to the requested page.</p>
      <div className="flexGrow">
        <button onClick={goBack}>Go Back</button>
      </div>
    </section> */}
      <Box
        //display={"flex"}
        //flexDirection="column"
        margin="auto"
        boxShadow={"5px 5px 15px "}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mt: { xs: 4, sm: 10 },
          mb: "auto",
          borderRadius: 3,
          maxWidth: "sm",
          minHeight: "400px",
          bgcolor: "burlywood",
        }}
      >
        <Typography variant="h3" sx={{ color: "blue" }}>
          Unauthorized.
        </Typography>

        <Typography variant="h5" sx={{ mt: 5 }}>
          You do not have access to the requested page.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mx: 2 }}>
          If you believe you should have an access to this page, please notify
          to the admin team.
        </Typography>
        <Button
          variant="outlined"
          sx={{ color: "blue", mt: 3 }}
          size="large"
          onClick={goBack}
        >
          Go Back
        </Button>
      </Box>
    </>
  );
};

export default Unauthorized;
