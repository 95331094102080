import { Route, Routes } from "react-router-dom";
import Login from "../auth/Login";
import Login2 from "../auth/Login2";

import PageNotFound from "../auth/PageNotFound";
import ProtectedRoutes from "../auth/ProtectedRoutes";
import SignIn from "../auth/SignIn";
import Unauthorized from "../auth/Unauthorized";

import Dashboard from "../components/dashboard/Dashboard";
import DashboardUI from "../components/dashboard/DashboardUI";
import Home from "../components/dashboard/Home";
import StudentFormSteps from "../components/student/StudentFormSteps";
import TeacherFormSteps from "../components/teacher/TeacherFormSteps";
import StaffFormSteps from "../components/staff/StaffFormSteps";
import SchoolFormSteps from "../components/school/SchoolFormSteps";
import MyDataGrid from "../MyDataGrid";

const ROLES = {
  User: 1818,
  Editor: 1919,
  Admin: 2001,
  //enum: ['basic', 'editor', 'admin'],
};

const ViewsLayout = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardUI />} />
      <Route
        element={
          <ProtectedRoutes
            allowedRoles={[ROLES.Admin, ROLES.Editor, ROLES.User]}
          />
        }
      >
        <Route path="dashboard" element={<Dashboard />}>
          <Route index element={<Home />} />

          <Route path="studentformsteps" element={<StudentFormSteps />} />
          <Route path="teacherformsteps" element={<TeacherFormSteps />} />
          <Route path="staffformsteps" element={<StaffFormSteps />} />
          <Route path="schoolformsteps" element={<SchoolFormSteps />} />
          <Route path="datagrid" element={<MyDataGrid />} />
          <Route path="signin" element={<SignIn />} />
          {/* <Route path="register" element={<Login />} /> */}

          <Route path="PageNotFound" element={<PageNotFound />} />
        </Route>
      </Route>

      <Route path="/login2" element={<Login2 />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default ViewsLayout;
