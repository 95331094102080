import { createContext, useState } from "react";

const AuthContext = createContext({});

// export function useAuth() {
//   return useContext(AuthContext);
// }
//   const useAuth = () => {
//     return useContext(AuthContext);
// }
const ROLES = {
  User: 1818,
  Editor: 1919,
  Admin: 2001,
  //enum: ['basic', 'editor', 'admin'],
};
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth, ROLES }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
