import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";
import useAuth from "./UseAuth";

export default function SignInSide() {
  const { auth } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      name: data.get("name"),
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const ROLES = {
    User: 1818,
    Editor: 1919,
    Admin: 2001,
    Super: 500,
    //enum: ['basic', 'editor', 'admin'],
  };
  const pp =
    //auth.role auth?.roles?.find((role) => allowedRoles?.includes(role))
    auth?.roles?.includes(ROLES.Admin)
      ? [
          { id: 1, name: "Admin" },
          { id: 2, name: "Editor" },
          { id: 3, name: "user" },
          { id: 4, name: "Super Admin" },
        ]
      : [
          { id: 1, name: "Admin" },
          { id: 2, name: "Editor" },
          { id: 3, name: "User" },
        ];
  const people = [
    { id: 1, name: "Admin", age: 15, role: [ROLES.Admin, ROLES.Super] },
    {
      id: 2,
      name: "Editor",
      age: 12,
      role: [ROLES.Admin, ROLES.Super, ROLES.Editor],
    },
    {
      id: 3,
      name: "user",
      age: 25,
      role: [ROLES.Admin, ROLES.Super, ROLES.Editor],
    },
    { id: 4, name: "Super Admin", age: 15, role: [ROLES.Super] },
  ];

  return (
    <Box margin="auto" sx={{ maxWidth: "500px", mt: 8 }}>
      <Paper
        elevation={6}
        square
        sx={{
          px: 6,
          pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 3,
          border: 2,
          borderColor: "blueviolet",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Add New User
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name "
            name="name"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <TextField
            required
            select
            label="Role "
            fullWidth
            margin="dense"
            defaultValue="1"
          >
            {/* <MenuItem value="1">Admin</MenuItem>
            <MenuItem value="0">Editor </MenuItem>
            <MenuItem value="2">User </MenuItem> */}

            {pp.map((p) => (
              <MenuItem key={"name-shared" + p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </TextField>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mx: 3, borderRadius: 3 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mx: 3, borderRadius: 3 }}
            >
              submit
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
