import React, { Fragment } from "react";

import { Box, TextField, styled, MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid2 version 2

const SchoolFormPart1 = (props) => {
  const { register, errors, watch } = props;

  const BlueTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "blue", //not hoover and focused
      },
    },
  });
  const OrangTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "orange", //not hoover and focused
      },
    },
  });
  return (
    <Fragment>
      <Box px={1}>
        <Grid2 container spacing={1}>
          <Grid2 xs={12} sm={12}>
            <TextField
              required
              label="School Name"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("school_name")}
              error={errors?.school_name ? true : false}
              helperText={errors?.school_name?.message}
            />
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <TextField
              required
              select
              label="School Type "
              fullWidth
              size="small"
              margin="dense"
              defaultValue="private"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("school_type")}
              error={errors?.school_type ? true : false}
              helperText={errors?.school_type?.message}
            >
              <MenuItem value="private">Quran Madrasa</MenuItem>
              <MenuItem value="private">Private School</MenuItem>
              <MenuItem value="public">Public School</MenuItem>
              <MenuItem value="charter">Charter School</MenuItem>
              <MenuItem value="orphan">Orphan School</MenuItem>
            </TextField>
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              required
              select
              label="School Level "
              fullWidth
              size="small"
              margin="dense"
              defaultValue="quran"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("school_level")}
              error={errors?.school_level ? true : false}
              helperText={errors?.school_level?.message}
            >
              <MenuItem value="quran">Quran </MenuItem>
              <MenuItem value="elementary">Elementary </MenuItem>
              <MenuItem value="secondary">Secondary </MenuItem>
              <MenuItem value="elementary/secondary">
                Elementary & Secondary{" "}
              </MenuItem>
            </TextField>
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              required
              label="School Principal Name"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("principal_name")}
              error={errors?.principal_name ? true : false}
              helperText={errors?.principal_name?.message}
            />
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <TextField
              required
              label="Principal Phone"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("principal_phone")}
              error={errors?.principal_phone ? true : false}
              helperText={errors?.principal_phone?.message}
            />
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <TextField
              label="Principal Email"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("principal_email")}
              error={errors?.principal_email ? true : false}
              helperText={errors?.principal_email?.message}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              required
              select
              label="Active "
              fullWidth
              size="small"
              margin="dense"
              defaultValue="1"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("active")}
              error={errors?.active ? true : false}
              helperText={errors?.active?.message}
            >
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No </MenuItem>
            </TextField>
          </Grid2>
        </Grid2>
      </Box>
    </Fragment>
  );
};

export default SchoolFormPart1;
