import ViewsLayout from "./view/ViewLayout";
function App() {
  return (
    <>
      <ViewsLayout />
    </>
  );
}

export default App;
