import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";

import {
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  Button,
  ListSubheader,
  TextField,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";

import { Link as Nav } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import { mainListItems, secondaryListItems } from "./ListItems";
import { useMemo, useState } from "react";

import DashboardUIMain from "./DashboardUIMain";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    // ...(!open && {
    //   overflowX: "hidden",
    //   transition: theme.transitions.create("width", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    //   width: theme.spacing(7),
    //   [theme.breakpoints.up("xs")]: {
    //     width: theme.spacing(0),
    //   },
    //   [theme.breakpoints.up("md")]: {
    //     width: theme.spacing(30),
    //   },
    // }),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: "1rem",
  width: "300px",
}));
//const mdTheme = createTheme();

function DashboardContent() {
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [dark, setDark] = useState(false);

  const darkTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: dark ? "dark" : "light",
        },
      }),
    [dark]
  );

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // const handleClose = () => {
  //   setOpenDialog(false);
  // };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex", direction: "column" }}>
        <CssBaseline />
        {/* 546e7a 8d6e63 063970" */}

        <AppBar position="absolute" elevation={0} sx={{ bgcolor: "#2196f3" }}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "82px",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                onClick={handleDrawerToggle}
                variant="outlined"
                startIcon={<MenuIcon />}
                sx={{
                  color: "inherit",
                  border: 2,
                  borderColor: "white",
                  //bgcolor: "black",
                  mr: "36px",
                  display: { md: "none" },
                  "&:hover": {
                    background: "none",
                    border: 2,
                    borderColor: "white",
                  },
                }}
              >
                Menu
              </Button>
              <Typography
                noWrap
                sx={{
                  display: { xs: "none", md: "flex" },
                  fontSize: "1.3rem",
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                LOGO
              </Typography>
            </Box>

            <Box
              sx={{
                alignItems: "center",
                //display: { xs: "none", sm: "flex", md: "flex" },
                //fontSize: { xs: 16, md: 20 },
                fontSize: "2.5rem",
                gap: 2,
              }}
            >
              <Typography
                noWrap
                sx={{ display: { xs: "flex", md: "none" }, fontSize: "1.2rem" }}
              >
                School Logo
              </Typography>

              <Typography
                noWrap
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                    fontSize: "1.3rem",
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                  },
                }}
              >
                Bahru Noor Fundation
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Button
                //onClick={handleOpenLoginDialog}
                //endIcon={<LoginIcon />}
                //fontSize large
                startIcon={<LockOpenIcon fontSize="large" />}
                size="large"
                component={Nav}
                to="dashboard"
                variant="contained"
                sx={{ borderRadius: 3, bgcolor: "#ff6e40" }}
              >
                LOGIN
              </Button>
              {/* <Button variant="outlined" onClick={handleOpenLoginDialog}>
                Open form dialog
              </Button> */}
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              width: drawerWidth,
              display: { xs: "none", sm: "none", md: "block" },
              //bgcolor: "#546e7a",
              //backgroundColor: "#efebe9",
            },
          }}
        >
          <List
            subheader={
              <ListSubheader component="div" sx={{ pt: 12, pb: 1 }}>
                <Typography variant="h5">Dashboard List</Typography>
              </ListSubheader>
            }
          >
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>

        <MuiDrawer
          variant="temporary"
          anchor="left"
          onClose={handleDrawerToggle}
          open={open}
          elevation={3}
          PaperProps={{
            sx: {
              width: drawerWidth,
            },
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: (theme) => theme.zIndex.appBar + 100,
            display: { xs: "block", md: "none" },
          }}
        >
          <Box role="presentation" onClick={() => setOpen(false)}>
            <List
              subheader={
                <ListSubheader component="div" sx={{ pt: 12, pb: 1 }}>
                  <Typography variant="h5">Dashboard List</Typography>
                </ListSubheader>
              }
            >
              {mainListItems}
              <Divider sx={{ my: 1 }} />
              {secondaryListItems}
            </List>
          </Box>
        </MuiDrawer>
        <DashboardUIMain />
        {/* <EarningCard isLoading={isLoading} /> */}
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
