import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "./UseAuth";

// const useAuth = () => {
//   const user = { loggedIn: true };
//   return user && user.loggedIn;
// };

const ProtectedRoutes = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  // return auth?.user ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/login" state={{ from: location }} replace />
  // );
  return auth?.roles?.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
