import React from "react";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";

import ListSubheader from "@mui/material/ListSubheader";
import SidebarItem from "./SidebarItem";
import SidebarData1 from "../../data/SidebarData1";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useAuth from "../../auth/UseAuth";

const drawerWidth = 240;

const Sidebar = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const { open, onClose, closeDrawer } = props;
  const { auth } = useAuth();
  //console.log(auth.roles);
  const content = (
    <List
      subheader={
        <ListSubheader sx={{ pt: 12, pb: 1 }}>
          <Typography variant="h5">
            {/* <DashboardIcon /> */}
            Dashboard List
          </Typography>
        </ListSubheader>
      }
    >
      {SidebarData1.map(
        (item, index) =>
          // auth?.roles?.find((role) => allowedRoles?.includes(role))
          auth?.roles?.find((role) => item?.role?.includes(role)) && (
            <SidebarItem
              key={index}
              item={item}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              roles={auth.roles}
              closeDrawer={closeDrawer}
            />
          )
      )}
    </List>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open
        PaperProps={{
          sx: {
            width: drawerWidth,
            display: { xs: "none", sm: "none", md: "block" },
          },
        }}
      >
        {content}
      </Drawer>

      <Drawer
        variant="temporary"
        anchor="left"
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: {
            //backgroundColor: "neutral.900",
            //color: "#FFFFFF",
            width: "100%",
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.appBar + 100,
          display: { xs: "block", md: "none" },
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

export default Sidebar;
