import React from "react";
// import kidsImage from "../../static/kids.jpg";
// import womenImage from "../../static/women.jpg";
// import menImage from "../../static/men.jpg";
import { Box, Link, Stack, styled, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid2 version 2
//import { Link } from "react-router-dom";

const StyledBox = styled(Box)({
  height: 200,
  width: "100%",
  cursor: "pointer",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
});
const StyledTypography = styled(Typography)({
  margin: "25% 50px 25% 50px",
  background: "white",
  opacity: "0.8",
});

export const footers = [
  {
    title: "Institute",
    description: ["Organization", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Charity feature",
      "Voluntary stuff",
      "Team feature",
      "Help stuff",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

//sx={{ backgroundImage: `url(${menImage})` }}
const Footer = () => {
  return (
    // <Box>
    //   <Stack
    //     direction={{ xs: "column", sm: "row" }}
    //     spacing={{ xs: 1, sm: 2, md: 4 }}
    //     mt={5}
    //   >
    //     <StyledBox>
    //       <StyledTypography align="center" variant="h3">
    //         Kids
    //       </StyledTypography>
    //     </StyledBox>
    //     <StyledBox>
    //       <StyledTypography align="center" variant="h3">
    //         Men
    //       </StyledTypography>
    //     </StyledBox>
    //     <StyledBox>
    //       <StyledTypography align="center" variant="h3">
    //         Women
    //       </StyledTypography>
    //     </StyledBox>
    //   </Stack>
    // </Box>

    <Grid2 container spacing={4} justifyContent="space-evenly">
      {footers.map((footer) => (
        <Grid2 xs={6} sm={3} key={footer.title}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            {footer.title}
          </Typography>
          <ul>
            {footer.description.map((item) => (
              <Link
                key={item}
                href="#"
                variant="subtitle1"
                color="text.secondary"
              >
                <Typography
                  //align="right"
                  //variant="h6"
                  color="text.primary"
                  gutterBottom
                >
                  <li>{item}</li>
                </Typography>
              </Link>
            ))}
          </ul>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default Footer;
