import React from "react";
import Carousel from "react-material-ui-carousel";
import { Card, CardMedia } from "@mui/material";

function Carousel2(props) {
  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      image: "https://source.unsplash.com/random",
      //image: "/luqman.jpg",
    },
    {
      name: "Random Name #3",
      description: "Hello World!",
      image: "https://source.unsplash.com/random/300*300",
      //image: "sahra.jpg", https://unsplash.com/s/photos/examples
    },
    {
      name: "Random Name #4",
      description: "Hello World!",
      image: "https://source.unsplash.com/featured",
      //image: "sahra.jpg",
    },
    {
      name: "Random Name #2",
      description: "Hello World!",
      image: "https://images.unsplash.com/1/type-away.jpg",
      //image: "sahra.jpg", https://source.unsplash.com/random/WIDTHxHEIGHT
    },
  ];

  return (
    <Carousel
      sx={{ width: "100%", height: "auto" }}
      autoPlay="true"
      //animation="fade"
      //indicators="false"
      duration="500"
      navButtonsAlwaysVisible="false"
      //navButtonsAlwaysInvisible="false"
      cycleNavigation="true"
      fullHeightHover="true"
      swipe="true"
      indicators={false}
      //swipe={true}
      animation="slide"
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <>
      {/* <Paper>
        <h2>{props.item.name}</h2>
        <p>{props.item.description}</p>
        <img src={props.item.image} />
        <Button className="CheckButton">Check it out!</Button>
      </Paper> */}

      <Card raised>
        {/* <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "red[500]" }} aria-label="recipe">
              R
            </Avatar>
          }
          // action={
          //   <IconButton aria-label="settings">
          //     {props}
          //   </IconButton>
          // }
          title="Shrimp and Chorizo Paella"
          subheader="September 14, 2016"
        /> */}
        <CardMedia
          component="img"
          height="520"
          image={props.item.image}
          alt="image"
          //paddingTop="75%"
          //sx={{ paddingTop: "75%" }}
        />
        {/* <CardMedia
          image={props.item.image}
          title={props.item.description}
          style={{
            height: 0,
            width: "100%",
            paddingTop: "75%",
          }}
        /> */}
        {/* <CardContent>
          <Typography variant="body2" color="text.secondary">
            coming
          </Typography>
        </CardContent> */}
      </Card>
    </>
  );
}

export default Carousel2;
