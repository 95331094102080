import React from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
// import * as IoIcons from "react-icons/io";
// import * as RiIcons from "react-icons/ri";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BoyIcon from "@mui/icons-material/Boy";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DashboardIcon from "@mui/icons-material/Dashboard";

const ROLES = {
  User: 1818,
  Editor: 1919,
  Admin: 2001,
  //enum: ['basic', 'editor', 'admin'],
};
const SidebarData1 = [
  {
    title: "Dashboard",
    path: "/dashboard",
    role: [ROLES.Admin],
    icon: <DashboardIcon />,
    iconClosed: "",
    iconOpened: "",
  },
  {
    title: "School",
    path: "#",
    role: [ROLES.User],
    icon: <SchoolIcon />,
    iconClosed: "",
    iconOpened: "",

    childrens: [
      {
        title: "New School",
        path: "schoolformsteps",
        icon: <SupervisedUserCircleIcon />,
      },
      {
        title: "All Schools",
        path: "datagrid",
        icon: <BoyIcon />,
      },
    ],
  },
  {
    title: "Student",
    path: "#",
    role: [ROLES.Editor],
    icon: <FamilyRestroomIcon />,
    iconClosed: "",
    iconOpened: "",

    childrens: [
      {
        title: "New Student",
        path: "studentformsteps",
        icon: <BoyIcon />,
      },
      {
        title: "All Students",
        path: "datagrid",
        icon: "",
      },
      {
        title: "Search ",
        path: "#",
        icon: "",
      },
    ],
  },
  {
    title: "Teacher",
    path: "#",
    role: [ROLES.User],
    icon: <AccountCircleIcon />,
    iconClosed: "",
    iconOpened: "",

    childrens: [
      {
        title: "New Teacher",
        path: "teacherformsteps",
        icon: "",
      },
      {
        title: "All Teachers",
        path: "datagrid",
        icon: "",
      },
      {
        title: "Search ",
        path: "#",
        icon: "",
      },
    ],
  },
  {
    title: "Staff",
    path: "#",
    role: [ROLES.Editor],
    icon: <PeopleIcon />,
    iconClosed: "",
    iconOpened: "",

    childrens: [
      {
        title: "New Staff",
        path: "staffformsteps",
        icon: "",
      },
      {
        title: "All Staffs",
        path: "datagrid",
        icon: "",
      },
    ],
  },
  {
    title: "Analysis",
    path: "#",
    role: [ROLES.Editor],
    icon: <AnalyticsIcon />,
  },
  {
    title: "Photos",
    path: "#",
    role: [ROLES.Editor],
    icon: <CameraAltIcon />,
    iconClosed: "",
    iconOpened: "",
    childrens: [
      {
        title: "Img upload",
        path: "#",
        icon: <InsertChartIcon />,
      },
      {
        title: "Excel Import",
        path: "#",
        icon: "",
      },
      {
        title: "Chart",
        path: "#",
        icon: <InsertChartIcon />,
      },
    ],
  },
  {
    title: "Admin",
    path: "#",
    role: [ROLES.Admin],
    icon: <AdminPanelSettingsIcon />,

    childrens: [
      {
        title: "Create User",
        path: "signin",
        icon: <InsertChartIcon />,
      },
      {
        title: "Delete User",
        path: "signin",
        icon: "",
      },
      {
        title: "Update User",
        path: "signin",
        icon: <InsertChartIcon />,
      },
      {
        title: "View Users",
        path: "signin",
        icon: <InsertChartIcon />,
      },
    ],
  },
];

export default SidebarData1;

// [
//   {
//     "title": "General",
//     "icon": "bi-gear-fill",
//     "childrens": [
//       {
//         "title": "Home",
//         "icon": "bi-house-fill",
//         "path": "/"
//       },
//       {
//         "title": "About",
//         "icon": "bi-info-circle-fill",
//         "path": "/about"
//       },
//       {
//         "title": "FAQ",
//         "icon": "bi-question-circle-fill"
//       }
//     ]
//   },
//   {
//     "title": "Account",
//     "icon": "bi-info-circle-fill",
//     "childrens": [
//       {
//         "title": "Login",
//         "path": "/login"
//       },
//       {
//         "title": "Register",
//         "path": "/register"
//       },
//       {
//         "title": "Forgot Password",
//         "path": "/forgot-password"
//       },
//       {
//         "title": "Reset Password",
//         "path": "/reset-password"
//       }
//     ]
//   },
//   {
//     "title": "Profile",
//     "icon": "bi-person-fill",
//     "childrens": [
//       {
//         "title": "Profile",
//         "path": "/profile"
//       },
//       {
//         "title": "Logout",
//         "path": "/logout"
//       }
//     ]
//   },
//   {
//     "title": "Advance",
//     "icon": "bi-view-list",
//     "childrens": [
//       {
//         "title": "Search",
//         "path": "/search"
//       },
//       {
//         "title": "History",
//         "path": "/history"
//       }
//     ]
//   },
//   {
//     "title": "Support",
//     "icon": "bi-question-circle-fill",
//     "path": "/support"
//   },
//   {
//     "title": "Report Bug",
//     "icon": "bi-bug",
//     "path": "/report-bug"
//   }
// ]
