import { useRef, useState, useEffect } from "react";
import useAuth from "./UseAuth";
import { useNavigate, useLocation } from "react-router-dom";

//-----------------------------------
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  styled,
  TextField,
} from "@mui/material";
//import React, { useState } from "react";
const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: "1rem",
  width: "300px",
}));

//-----------------------------------------
const LOGIN_URL = "/auth";

const Login2 = () => {
  // const { handleCloseLoginDialog } = props;

  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const errRef = useRef();

  const [user, setUser] = useState("Ali");
  const [pwd, setPwd] = useState("water");
  const [roles, setRoles] = useState([2001, 1818, 1919]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [openDialog, setOpenDialog] = useState(true);

  useEffect(() => {
    setErrMsg("");
  }, [email]);

  const currentUser = {
    user: "Ali",
    pswd: "unkown",
    email: "email@gmail.com",
    roles: [1818],
  };
  const userTemp = [
    { user: "Mohamed", pswd: "12345", email: "mohamud5@gmail.com" },
    { user: "water", pswd: "12345", email: "water@gmail.com" },
  ];

  // User: 1818,
  // Editor: 1919,
  // Admin: 2001,
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setAuth({
    //   user: currentUser.user,
    //   pwd: currentUser.pswd,
    //   roles: currentUser.roles,
    // });

    //userTemp?.email?.find((email) => allowedRoles?.includes(email))
    //console.log(userTemp.find((el) => el.email === "mohamud5@gmail.com"));
    //const em =

    if (userTemp.find((el) => el.email === email)) {
      console.log("Yes");
      setAuth({ user, pwd, roles });
      navigate(from, { replace: true });
    } else {
      console.log("No");
      setErrMsg("Missing Username or Password");
      errRef.current.focus();
    }

    //setAuth({ user, pwd, roles });

    //setAuth({ user, pwd, roles, accessToken });
    //navigate(from, { replace: true });
  };
  //--------------------------------
  //const { handleClose } = props;

  // const handleSubmit2 = (e) => {
  //   e.preventDefault();
  //   //props.handleClose();
  //   handleClose();
  // };
  //-----------------------------
  // const handleOpen = () => {
  //   setOpenDialog(true);
  // };

  const handleClose = () => {
    setOpenDialog(false);
    setAuth({ user: null, pwd: null, roles: null });
    navigate("/", { replace: true });
    //setAuth({ user, pwd, roles });
    // setAuth(currentUser);
    // //setAuth({ user, pwd, roles, accessToken });
    // console.log(location.state?.from?.pathname || "/");
    // console.log(location.pathname);
    // console.log(currentUser.name);
    // navigate(from, { replace: true });
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Login Page</DialogTitle>
        <Box
          sx={{
            background: "ghostwhite",
            borderRadius: 3,
          }}
        >
          <Box
            ref={errRef}
            sx={{ color: "red", display: "flex", justifyContent: "center" }}
          >
            {errMsg}
          </Box>

          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "2rem",
              minHeight: 350,
            }}
          >
            {/* <StyledTextField
              label="First Name"
              variant="filled"
              required
              value={firstName}
              // onChange={(e) =>
              //   setFirstName(e.target.value)
              // }
              onChange={(e) => setFirstName(e.target.value)}
            /> */}
            {/* <StyledTextField
              label="Last Name"
              variant="filled"
              onChange={(e) => setFirstName(e.target.value)}
              // required
              // value={lastName}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              //   setLastName(e.target.value)
              // }
            /> */}
            <StyledTextField
              label="Email"
              type="email"
              variant="filled"
              required
              onChange={(e) => setEmail(e.target.value)}
              // sx={{
              //   "& .MuiFormLabel-root": {
              //     color: "white",
              //   },
              //   "& .MuiFormLabel-root.Mui-focused": {
              //     color: "black",
              //   },
              // }}
              //inputProps={{ style: { color: "white" } }}
              // required
              // value={email}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              //   setEmail(e.target.value)
              // }
            />
            <StyledTextField
              label="Password"
              type="password"
              variant="filled"
              required
              onChange={(e) => setPassword(e.target.value)}
              // required
              // value={password}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              //   setPassword(e.target.value)
              // }
            />

            {/* <TextField
              required
              select
              label="Role "
              fullWidth
              size="small"
              margin="dense"
              defaultValue="1"
            >
              <MenuItem value="1">Admin</MenuItem>
              <MenuItem value="0">Editor </MenuItem>
              <MenuItem value="2">User </MenuItem>
            </TextField> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                onClick={handleClose}
                variant="contained"
                sx={{ mt: 3, mx: 3, borderRadius: 3 }}
              >
                Cancel 4
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mx: 3, borderRadius: 3 }}
              >
                Sign In
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default Login2;
