import React, { useState } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { createTheme } from "@mui/material/styles";
import dayjs from "dayjs";

//import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import StudentFormOne from "../student/StudentFormOne";
import StudentFormTwo from "../student/StudentFormTwo";
import validationSchema from "./StudentValidationSchema";
import Review from "../student/Review";
import StudentGaurdian from "../student/StudentGaurdian";
import useAuth from "../../auth/UseAuth";

const steps = ["Information", "Address", "Gardian", "Review"];

const theme = createTheme();

export default function StudentFormSteps() {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const { auth } = useAuth();

  const currentValidationSchema = validationSchema[activeStep];

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(currentValidationSchema),
  });

  const handleNext = (data) => {
    setFormValues({
      ...formValues,
      ...data,
    });
    console.log(data);
    //console.log(formValues);
    //console.log(dayjs().year());
    //console.log(dayjs(data.birth_date).year());
    //console.log()
    //console.log(dayjs(new Date()).fromNow());
    //console.log(dayjs.diff(dayjs(data.birth_date), "years") >= 10);
    console.log(dayjs().diff(dayjs(data.birth_date), "years"));
    //b.diff(a, 'day')
    setActiveStep(activeStep + 1);
    // console.log(activeStep);
  };

  const handleBack = (data) => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <StudentFormOne register={register} errors={errors} watch={watch} />
        );
      case 1:
        return (
          <StudentFormTwo register={register} errors={errors} auth={auth} />
        );
      case 2:
        return <StudentGaurdian register={register} errors={errors} />;
      case 3:
        return <Review formValues={formValues} />;
      default: {
        // <React.Fragment>
        //   <Typography variant="h5" gutterBottom>
        //     Thank you for your order.
        //   </Typography>
        //   <Typography variant="subtitle1">
        //     Your order number is #2001539. We have emailed your order
        //     confirmation, and will send you an update when your order has
        //     shipped.
        //   </Typography>
        // </React.Fragment>;
        throw new Error("Unknown step");
      }
    }
  }

  return (
    <>
      <Box
        display={"flex"}
        //maxWidth="sm"
        margin="auto"
        boxShadow={"5px 5px 15px "}
        sx={{
          mt: { xs: 4, sm: 10 },
          mb: "auto",
          borderRadius: 3,
          maxWidth: "sm",
        }}
      >
        <Paper
          variant="outlined"
          //maxWidth="sm"
          sx={{
            p: 2,
            border: 2,
            borderColor: "blueviolet",
            borderRadius: 3,
            width: "100%",
            maxWidth: "sm",
            //bgcolor: "#e1f5fe",
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            sx={{ pt: 3, pb: 3 }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography
            component="h1"
            variant="h5"
            align="center"
            sx={{ pb: 2, color: "primary.main" }}
          >
            {/* <Avatar sx={{ bgcolor: "secondary.main" }}>
              <FamilyRestroomIcon />
            </Avatar> */}
            Student Form Part {activeStep + 1}
          </Typography>
          <Box
            sx={{
              border: 2,
              borderColor: "blueviolet",
              borderRadius: 3,
              px: 2,
              py: 2,
              mx: 2,
              //bgcolor: "deepskyblue", //fff3e0 //fbe9e7
              bgcolor: "#fff3e0", //ede7f6 //e1f5fe//
            }}
          >
            {activeStep !== steps.length && getStepContent(activeStep)}
            {activeStep === steps.length && (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your completion.
                </Typography>
                <Typography variant="subtitle1">
                  You have successfully completed the form.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleBack}
                  sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                >
                  Back {activeStep}
                </Button>
              </React.Fragment>
            )}
          </Box>
          <React.Fragment>
            {activeStep !== steps.length && (
              <React.Fragment>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {activeStep !== 0 && (
                    <Button
                      variant="contained"
                      onClick={handleBack}
                      sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                    >
                      Back {activeStep}
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleNext)}
                      sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                    >
                      Place order
                    </Button>
                  )}
                  {activeStep !== steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleNext)}
                      sx={{ mt: 3, ml: 1, borderRadius: 3 }}
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
          {/* </Box> */}
        </Paper>
      </Box>
    </>
  );
}
