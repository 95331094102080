import React, { Fragment } from "react";

import { Box, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid2 version 2

const TeacherFormThree = (props) => {
  const { register, errors } = props;
  return (
    <Fragment>
      <Box px={1}>
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <Grid xs={12} sm={12}>
              <TextField
                required
                label="Duty"
                fullWidth
                size="small"
                margin="dense"
                {...register("duty")}
                error={errors?.duty ? true : false}
                helperText={errors?.duty?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="major"
                fullWidth
                size="small"
                margin="dense"
                {...register("major")}
                error={errors?.major ? true : false}
                helperText={errors?.major?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="minor"
                fullWidth
                size="small"
                margin="dense"
                {...register("minor")}
                error={errors?.minor ? true : false}
                helperText={errors?.minor?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="School Graduated"
                fullWidth
                size="small"
                margin="dense"
                {...register("school_graduated")}
                error={errors?.school_graduated ? true : false}
                helperText={errors?.school_graduated?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="degree"
                fullWidth
                size="small"
                margin="dense"
                {...register("degree")}
                error={errors?.degree ? true : false}
                helperText={errors?.degree?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Subject Teaching"
                fullWidth
                size="small"
                margin="dense"
                {...register("subject_teaching")}
                error={errors?.subject_teaching ? true : false}
                helperText={errors?.subject_teaching?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="year Started Teaching  "
                fullWidth
                size="small"
                margin="dense"
                {...register("year_started_teaching")}
                error={errors?.year_started_teaching ? true : false}
                helperText={errors?.year_started_teaching?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Account Type  "
                fullWidth
                size="small"
                margin="dense"
                {...register("account_type")}
                error={errors?.account_type ? true : false}
                helperText={errors?.account_type?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Account Number  "
                fullWidth
                size="small"
                margin="dense"
                {...register("account_number")}
                error={errors?.account_number ? true : false}
                helperText={errors?.account_number?.message}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default TeacherFormThree;
