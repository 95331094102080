import * as yup from "yup";
import dayjs from "dayjs";

export default [
  yup.object().shape({
    //[0]
    school_name: yup.string().required("School Name is required"),
    school_type: yup.string().required("School type is required"),
    school_level: yup.string().required("Village name is required"),

    principal_name: yup.string().required("Principle Name is required"),
    principal_phone: yup.string().required("Principle phone is required"),
    //principal_phone_2: yup.string().required("Principle phone 2 is required"),
    //principal_email: yup.string().required("Principle email is required"),
    active: yup.string().required("active is required"),
  }),
  yup.object().shape({
    //[1]
    country: yup.string().required("Country is required"),
    state_name: yup.string().required("State name is required"),
    region_name: yup.string().required("Region name is required"),
    district: yup.string().required("District name is required"),
    village: yup.string().required("Village name is required"),
  }),
  yup.object().shape({
    //[1]
    school_phone: yup.string().required("School phone is required"),
    school_email: yup.string().required("School email is required"),
    school_website: yup.string().required("School Website  is required"),
    school_owner_ship: yup.string().required("School Ownership is required"),
  }),
  yup.string().required("Email is required").email("Email is invalid"),
  // }),
  yup.object().shape({
    //[3] Login information
    //full_name: yup.string(),
  }),
  yup.object().shape({
    //[3] Login information
    //name: yup.string(),
  }),
];
