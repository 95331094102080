import React, { Fragment } from "react";

import { Box, MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid2 version 2

const StudentGaurdian = (props) => {
  const { register, errors } = props;
  return (
    <Fragment>
      <Box px={1}>
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <Grid xs={12} sm={12}>
              <TextField
                required
                label="Guardian Full Name"
                fullWidth
                size="small"
                margin="dense"
                {...register("guardian_name")}
                error={errors?.guardian_name ? true : false}
                helperText={errors?.guardian_name?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Guardian Email"
                fullWidth
                size="small"
                margin="dense"
                {...register("guardian_email")}
                error={errors?.guardian_email ? true : false}
                helperText={errors?.guardian_email?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Guardian Phone"
                fullWidth
                size="small"
                margin="dense"
                {...register("guardian_phone")}
                error={errors?.guardian_phone ? true : false}
                helperText={errors?.guardian_phone?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Guardian Phone 2"
                fullWidth
                size="small"
                margin="dense"
                {...register("guardian_phone2")}
                error={errors?.guardian_phone2 ? true : false}
                helperText={errors?.guardian_phone2?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Relationship"
                select
                defaultValue=""
                fullWidth
                size="small"
                color="primary"
                //margin="dense"
                {...register("relationship")}
                error={errors?.relationship ? true : false}
                helperText={errors?.relationship?.message}
              >
                <MenuItem value="Father">Father</MenuItem>
                <MenuItem value="Mother">Mother</MenuItem>
                <MenuItem value="Sibling">Sibling </MenuItem>
                <MenuItem value="Ancle">Ancle </MenuItem>
                <MenuItem value="Aunt">Aunt </MenuItem>
                <MenuItem value="Others">Others </MenuItem>
              </TextField>
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Student Mother Phone"
                fullWidth
                size="small"
                margin="dense"
                {...register("mother_phone")}
                error={errors?.mother_phone ? true : false}
                helperText={errors?.mother_phone?.message}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                required
                label="Student Father Phone"
                fullWidth
                size="small"
                margin="dense"
                {...register("father_phone")}
                error={errors?.father_phone ? true : false}
                helperText={errors?.father_phone?.message}
              />
            </Grid>
            {/* <Grid xs={12} sm={12}>
              <TextField
                required
                label="Student Mother Full Name"
                fullWidth
                size="small"
                margin="dense"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "orange" },
                  },
                }}
                {...register("student_mother_name")}
                error={errors?.mother_name ? true : false}
                helperText={errors?.mother_name?.message}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default StudentGaurdian;
