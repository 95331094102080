import * as React from "react";
import { Outlet } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Typography,
  Link,
  Divider,
  IconButton,
  Container,
} from "@mui/material";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import useAuth from "../../auth/UseAuth";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useMemo, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid2 version 2
import Footer from "./Footer";

const drawerWidth = 240;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://c.com/">
        {" "}
        Your Website{" "}
      </Link>{" "}
      {new Date().getFullYear()} {"."}
    </Typography>
  );
}

//const mdTheme = createTheme();

function DashboardContent() {
  const { auth } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [dark, setDark] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const darkTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: dark ? "dark" : "light",
        },
      }),
    [dark]
  );
  const closeDrawer = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        }}
      >
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Sidebar
            //onClose={() => setOpen(false)}
            onClose={closeDrawer}
            open={open}
            closeDrawer={closeDrawer}
          />
        </Box>
        <Navbar open={open} setOpen={setOpen} />
        {/* <Navbar10 open={open} setOpen={setOpen} /> */}

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            component="main"
            sx={{
              flex: 1,
              direction: "column",
              justifyContent: "center",
              pt: 5,
              px: 1,
            }}
          >
            <Box
              sx={{
                pt: 10,
                flexGrow: 1,
                height: "auto",
                overflow: "auto",
              }}
            >
              <Outlet />
              {/* //bgcolor: "#eaeff1"  maxWidth="xs" */}
            </Box>
            {/* <IconButton onClick={() => setDark(!dark)}>
              {dark ? <Brightness7 /> : <Brightness4 />}
            </IconButton> */}
          </Box>

          <Divider />

          <Container
            maxWidth="md"
            component="footer"
            sx={{
              // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              mt: 1,
              //py: [1, 3],
              pb: 3,
            }}
          >
            <Footer />
            <Copyright sx={{ mt: 2 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
