import * as yup from "yup";
import dayjs from "dayjs";

export default [
  yup.object().shape({
    //[0]
    first_name: yup.string().required("First Name is required"),
    middle_name: yup.string().required("Middle Name is required"),
    last_name: yup.string().required("Last Name is required"),
    mother_name: yup.string().required("Mother Full Name is required"),
    gender: yup.string().required("Gender is required"),
    birth_place: yup.string().required("Birth Place is required"),
    birth_date: yup
      .string()
      .required("DOB is Required")
      .test("DOB", "No age less than 5 years ", (value) => {
        return dayjs().diff(dayjs(value), "years") >= 5;
      }),
  }),
  yup.object().shape({
    //[1]
    country: yup.string().required("Country is required"),
    state_name: yup.string().required("State name is required"),
    region_name: yup.string().required("Region name is required"),
    district: yup.string().required("District name is required"),
    village: yup.string().required("Village name is required"),
    nationality: yup.string().required("Nationality is required"),

    active: yup.string().required("Active status is required"),
    //authorized_name: yup.string().required("Authorized name is required"),
    school_id: yup.string().required("Select school"),
    disability: yup.string().required("Disability is required"),
  }),
  yup.object().shape({
    //[2] guarantor part
    guarantor_name: yup.string().required("guarantor Name is required"),
    guarantor_email: yup
      .string()
      .required("Email is required")
      .email("Email is invalid"),
    guarantor_phone: yup.string().required("Phone number is required"),
    //guarantor_phone2: yup.string().required("Last Name is required"),
    //mother_name: yup.string().required("Birth Date is required"),
    //mother_phone: yup.string().required("Birth Place is required"),
    //father_phone: yup.string().required("Gender is required"),
    relationship: yup.string().required("Relationship is required"),
  }),
  // yup.object().shape({
  //   //[3]
  //   phone: yup.string().required("Phone is required"),
  //   nationality: yup.string().required("First Name is required"),
  //   image_url: yup.string().required("Image is required"),
  //   active: yup.string().required("First Name is required"),
  //   school_id: yup.string().required("First Name is required"),
  //   authorized_name: yup.string().required("First Name is required"),
  //   email: yup.string().required("Email is required").email("Email is invalid"),
  // }),
  yup.object().shape({
    //[3] Login information
    full_name: yup.string(),
  }),
  yup.object().shape({
    //[3] Login information
    name: yup.string(),
  }),
  // yup.object().shape({
  //   //[4] Login information
  //   full_name: yup.string().required("Full name is required"),
  //   username: yup
  //     .string()
  //     .required("Username is required")
  //     .min(6, "Username must be at least 6 characters")
  //     .max(20, "Username must not exceed 20 characters"),
  //   password: yup
  //     .string()
  //     .min(5, "minimum length of 5")
  //     .max(12, "password should have a maximum length of 12")
  //     .required("password is required"),
  //   confirmPassword: yup
  //     .string()
  //     .oneOf([yup.ref("password")])
  //     .required("confirm password is required"),
  // }),
];
