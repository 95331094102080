import * as React from "react";

import { Box, Toolbar, Typography, Divider } from "@mui/material";

import { Container, Grid, Paper, Link } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid2 version 2

import { useState } from "react";
import {
  LineChartExample,
  LineChart2Example,
  BarChartExample,
  PieChartExample,
  AreaChartExample,
} from "../charts/PieCharts";
import Carousel2 from "./Carousel";
import Footer, { footers } from "./Footer";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        {" "}
        Your Website{" "}
      </Link>{" "}
      {new Date().getFullYear()} {"."}
    </Typography>
  );
}

const gridSpacing = 2;

const DashboardUIMain = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar sx={{ height: "70px" }} />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                      backgroundColor: "#e3f2fd",
                    }}
                  >
                    <PieChartExample />
                  </Paper>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                      backgroundColor: "#efebe9",
                    }}
                  >
                    <AreaChartExample />
                  </Paper>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={6} xs={12} md={6} lg={12}>
                      <Paper
                        sx={{
                          //p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "auto",
                          backgroundColor: "#e3f2fd",
                        }}
                      >
                        <Carousel2 />
                      </Paper>
                    </Grid>
                    <Grid item sm={6} xs={12} md={6} lg={12}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "auto",
                          backgroundColor: "#e3f2fd",
                        }}
                      ></Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                      backgroundColor: "#efebe9",
                    }}
                  >
                    <LineChart2Example />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                      backgroundColor: "#e3f2fd",
                    }}
                  >
                    <LineChartExample />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <BarChartExample />
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Divider sx={{ mt: 10 }} />

        <Container
          maxWidth="md"
          component="footer"
          sx={{
            mt: 5,
            pb: 3,
          }}
        >
          <Footer />
          <Copyright sx={{ mt: 2 }} />
        </Container>
      </Box>
    </>
  );
};

export default DashboardUIMain;
