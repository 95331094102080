import React, { Fragment } from "react";

import { Box, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid2 version 2

const SchoolFormPart2 = (props) => {
  const { register, errors, auth } = props;

  return (
    <Fragment>
      <Box px={1}>
        <Grid container spacing={1}>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="Country"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("country")}
              error={errors?.country ? true : false}
              helperText={errors?.country?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="State"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("state_name")}
              error={errors?.state_name ? true : false}
              helperText={errors?.state_name?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="Region"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("region_name")}
              error={errors?.region_name ? true : false}
              helperText={errors?.region_name?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="District"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("district")}
              error={errors?.district ? true : false}
              helperText={errors?.district?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="Village"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("village")}
              error={errors?.village ? true : false}
              helperText={errors?.village?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}></Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="School Phone"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("school_phone")}
              error={errors?.school_phone ? true : false}
              helperText={errors?.school_phone?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="School Email"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("school_email")}
              error={errors?.school_email ? true : false}
              helperText={errors?.school_email?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="School Website"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("school_website")}
              error={errors?.school_website ? true : false}
              helperText={errors?.school_website?.message}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              label="school OwnerShip"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("school_owner_ship")}
              error={errors?.school_owner_ship ? true : false}
              helperText={errors?.school_owner_ship?.message}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default SchoolFormPart2;
