import React from "react";
import { Pets } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Brightness1SharpIcon from "@mui/icons-material/Brightness1Sharp";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import ArticleSharpIcon from "@mui/icons-material/ArticleSharp";
import useAuth from "../../auth/UseAuth";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //   ...(open && {
  //     marginLeft: drawerWidth,
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     transition: theme.transitions.create(["width", "margin"], {
  //       easing: theme.transitions.easing.sharp,
  //       duration: theme.transitions.duration.enteringScreen,
  //     }),
  //   }),
}));

const Navbar = (props) => {
  const { open, setOpen } = props;
  const { auth } = useAuth();
  const { setAuth } = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const toggleMenue = () => {
    setAuth({ user: null, pwd: null, roles: null });
  };
  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "greenyellow" : "white",
      textDecoration: "none",
      fontSize: 15,
    };
  };

  const MenuItems = [
    { name: "HOOME", Link: "/" },
    { name: "SCHOOL", Link: "schoolformsteps", indexP: 0 },
    { name: "TEACHER", Link: "teacherformsteps", indexP: 1 },
    { name: "STUDENT", Link: "studentformsteps", indexP: 2 },
    { name: "STAFF", Link: "staffformsteps", indexP: 3 },
    // { Name: "Admin ", Link: "studentformsteps" },
  ];
  const settings = [
    { name: "Profile", link: "/login2", hundle: "" },
    { name: "Logout", link: "/", hundle: "toggleMenue" },
  ];
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState("one");

  const handleChange55 = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "82px",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              onClick={toggleDrawer}
              edge="start"
              color="inherit"
              size="large"
              sx={{ display: { xs: "inline-flex", md: "none" } }}
            >
              <MenuIcon fontSize="big" />
              <Typography sx={{ mx: 2 }}>NUUR 2</Typography>
            </IconButton>

            <Pets
              edge="start"
              color="inherit"
              sx={{ display: { xs: "none", md: "inline-flex" } }}
            />
            <Typography
              textAlign="center"
              sx={{ display: { xs: "none", md: "inline-flex" } }}
            >
              NUUR
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: "center",
              display: { xs: "none", sm: "flex", md: "flex" },
              fontSize: { xs: 16, md: 20 },
              gap: 2,
            }}
          >
            {MenuItems.map((item) => (
              <NavLink key={item.name} to={item.Link} style={navLinkStyles}>
                {item.name}
              </NavLink>
            ))}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box
              sx={{
                mx: 4,
                //display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              {/* <ArticleSharpIcon sx={{ fontSize: 25 }} />
            <AccessTimeSharpIcon sx={{ fontSize: 25 }} />
            <Brightness1SharpIcon sx={{ fontSize: 25 }} /> */}
              <ToggleButtonGroup
                sx={{ color: "red" }}
                color="success"
                //value={alignment}
                exclusive
                //onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="web" sx={{ color: "red" }}>
                  <ArticleSharpIcon sx={{ fontSize: 25 }} />
                </ToggleButton>
                <ToggleButton value="android" sx={{ color: "white" }}>
                  <AccessTimeSharpIcon sx={{ fontSize: 25 }} />
                </ToggleButton>
                <ToggleButton value="ios" sx={{ color: "yellow" }}>
                  <Brightness1SharpIcon sx={{ fontSize: 25 }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="User">
                  {auth?.user?.charAt(0)?.toUpperCase()}
                </Avatar>
                {/* <Avatar
                //variant="rounded"
                src=" https://source.unsplash.com/random"
                //sx={{ width: 46, height: 46 }}
              /> */}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={setting.hundle}>
                  <NavLink
                    to={setting.link}
                    style={{ textDecoration: "none" }}
                    //style={({ isActive }) => ({ textDecoration: "none" })}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Navbar;
