import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Carousel2 from "./Carousel";

import Card from "./Card";

const mdTheme = createTheme();

function HomeContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            //height: "auto",
            //overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8} container spacing={2}>
                <Grid item xs={12} md={6} lg={8}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: 320,
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      Chart is coming 1
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: 320,
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      Chart is coming 1
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Paper>
                    <Carousel2 />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Paper>
                    <Carousel2 />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Paper>
                    <Carousel2 />
                  </Paper>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} lg={4} container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Card />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Card />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Card />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Home() {
  return <HomeContent />;
}
