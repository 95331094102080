import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <>
      <Box
        //display={"flex"}
        //flexDirection="column"
        margin="auto"
        boxShadow={"5px 5px 15px "}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mt: { xs: 4, sm: 10 },
          mb: "auto",
          borderRadius: 3,
          maxWidth: "sm",
          minHeight: "300px",
          bgcolor: "burlywood",
        }}
      >
        <Typography variant="h5">Page Not Found.</Typography>
        <Button
          variant="outlined"
          sx={{ color: "blue", mt: 5 }}
          size="large"
          onClick={goBack}
        >
          Go Back
        </Button>
      </Box>

      {/* <section>
      <h1>Page Not Found</h1>
      <br />
      <p>Page Not Found.</p>
      <div className="flexGrow">
        <button onClick={goBack}>Go Back</button>
      </div>
    </section> */}
    </>
  );
};

export default PageNotFound;
