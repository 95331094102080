import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Box,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";

const mapper = {
  kills: "Solo Kills Total",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Review({ formValues }) {
  //const propertyNames = Object.keys(formValues);
  //console.log(propertyNames);
  //const propertyValues = Object.values(formValues);
  //console.log(propertyValues);
  //console.log(Object.values(formValues));
  //console.log(Object.keys(formValues));
  // console.log(formValues.first_name);
  // console.log(Object.entries(formValues));

  // for (const entry of Object.entries(formValues)) {
  //   console.log(`${entry[0]} = ${entry[1]}`);
  // }
  // //const entries = Object.entries(formValues);
  //console.log(entries);

  //console.log(Object.keys(propertyValues));

  return (
    <React.Fragment>
      <Box px={1} sx={{ maxWidth: "sm" }}>
        <Typography variant="h6" gutterBottom>
          Student information summary
        </Typography>

        <TableContainer component={Paper} width="100%">
          <Table sx={{ border: 0, width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell> FIELD NAME</StyledTableCell>
                <StyledTableCell align="left">FIELD VALUE</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.entries(formValues).map(([key, value], i) => (
                <StyledTableRow
                  key={mapper[key] ? mapper[key] : key}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {mapper[key]
                      ? mapper[key].toUpperCase()
                      : key?.toUpperCase()}
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ color: "brown" }}>
                    {value}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <List disablePadding>
        {Object.entries(formValues).map(([key, value], i) => (
          <ListItem key={mapper[key] ? mapper[key] : key} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={mapper[key] ? mapper[key] : key} />
            <Typography variant="body2">{value}</Typography>
          </ListItem>
        ))}
      </List> */}
    </React.Fragment>
  );
}
