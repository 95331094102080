import React, { Fragment } from "react";

import { Box, TextField, MenuItem, Avatar } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid2 version 2
import dayjs from "dayjs";

const StaffFormOne = (props) => {
  const { register, errors, watch } = props;
  const birth_date = watch("birth_date");

  return (
    <Fragment>
      <Box px={1}>
        <Grid2 container spacing={1}>
          <Grid2 xs={12} sm={6}>
            <TextField
              required
              label="First Name"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("first_name")}
              error={errors?.first_name ? true : false}
              //error={errors.first_name}
              helperText={errors?.first_name?.message}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              required
              label="Middle Name"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("middle_name")}
              error={errors?.middle_name ? true : false}
              helperText={errors?.middle_name?.message}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              required
              label="Last Name"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("last_name")}
              error={errors?.last_name ? true : false}
              helperText={errors?.last_name?.message}
            />
          </Grid2>

          <Grid2 item xs={12} sm={6} sx={{ pt: 1.3 }}>
            <TextField
              label="Gender "
              select
              defaultValue=""
              fullWidth
              size="small"
              color="primary"
              //margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("gender")}
              error={errors?.gender ? true : false}
              helperText={errors?.gender?.message}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </TextField>
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              required
              label="Birth Place"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("birth_place")}
              error={errors?.birth_place ? true : false}
              helperText={errors?.birth_place?.message}
            />
          </Grid2>
          <Grid2 xs={11} sm={5}>
            <TextField
              required
              label="Birth Date"
              type="date"
              defaultValue=""
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("birth_date")}
              error={errors?.birth_date ? true : false}
              helperText={errors?.birth_date?.message}
            />
          </Grid2>
          <Grid2 xs={1} sm={1} sx={{ pt: 1.3 }}>
            {birth_date && (
              <>
                <Avatar sx={{ bgcolor: "secondary.main" }}>
                  {dayjs()?.diff(dayjs(birth_date), "years")}
                </Avatar>
              </>
            )}
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              label="Staff Email"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "blue" },
                },
              }}
              {...register("email")}
              error={errors?.email ? true : false}
              helperText={errors?.email?.message}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              label="Staff Phone"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#ff9800" },
                },
              }}
              {...register("Phone")}
              error={errors?.email ? true : false}
              helperText={errors?.email?.message}
            />
          </Grid2>
          <Grid2 xs={12} sm={12}>
            <TextField
              required
              label="Staff Mother Full Name"
              fullWidth
              size="small"
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "orange" },
                },
              }}
              {...register("mother_name")}
              error={errors?.mother_name ? true : false}
              helperText={errors?.mother_name?.message}
            />
          </Grid2>
          {/* <Grid2 xs={12} sm={12}>
            <TextField
              type="File"
              required
              accept="image/*"
              //label="Image"
              fullWidth
              size="small"
              margin="dense"
              {...register("image")}
              error={errors?.image ? true : false}
              helperText={errors?.image?.message}
            />
          </Grid2> */}
          {/* <input type="file" id="file" accept='image/*' multiple onChange={handleImageChange} /> */}
        </Grid2>
      </Box>
    </Fragment>
  );
};

export default StaffFormOne;
