import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import useAuth from "../src/auth/UseAuth";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  { field: "age", headerName: "Age", type: "number", width: 90 },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, "firstName") || ""} ${
        params.getValue(params.id, "lastName") || ""
      }`,
  },
  {
    field: "button",
    headerName: "Button",
    sortable: false,
    renderCell: (params) => (
      <strong>
        {/* {params.value.getFullYear()} */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
        >
          Open
        </Button>
      </strong>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        const api = params.api;
        const thisRow = {};

        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );

        return alert(JSON.stringify(thisRow, null, 4));
      };

      return <Button onClick={onClick}>Click</Button>;
    },
  },
];

const rows = [
  { id: 129, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 222, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 301, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 204, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 560, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 600, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 170, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 812, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 913, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export default function MyDataGrid() {
  const [selectionModel, setSelectionModel] = React.useState(() =>
    rows.filter((r) => r.age > 40).map((r) => r.id)
  );
  const [selectedRows, setSelectedRows] = React.useState();

  const { ROLES } = useAuth();

  //   const onRowsSelectionHandler = (ids) => {
  //     const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
  //     console.log(selectedRowsData);
  //   };

  return (
    <Box sx={{ height: 585, width: 800, margin: "auto" }}>
      <DataGrid
        //rowHeight={52}
        //checkboxSelection
        rows={rows}
        columns={columns}
        checkboxSelection={false}
        //rowHeight={25}
        //-----> pagination={false}
        //disableSelectionOnClick
        //onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)} call function
        selectionModel={selectionModel}
        onSelectionModelChange={(e) => {
          setSelectionModel(e);
          const selectedIDs = new Set(e);
          const selectedRows = rows.filter((r) => selectedIDs.has(r.id));
          setSelectedRows(selectedRows);
          //alert(JSON.stringify(selectedRows, null, 4));
        }}
      />
      <pre>{JSON.stringify(selectedRows, null, 4)}</pre>
      <pre>{ROLES.Admin}</pre>
    </Box>
  );
}

//-------------------------------------

// import * as React from "react";
// import { DataGrid } from "@mui/x-data-grid";
// //import { useDemoData } from "@mui/x-data-grid-generator";

// const columns = [
//   { field: "id", headerName: "ID", width: 70 },
//   { field: "firstName", headerName: "First name", width: 130 },
//   { field: "lastName", headerName: "Last name", width: 130 },
//   { field: "age", headerName: "Age", type: "number", width: 90 },
//   {
//     field: "fullName",
//     headerName: "Full name",
//     description: "This column has a value getter and is not sortable.",
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.getValue(params.id, "firstName") || ""} ${
//         params.getValue(params.id, "lastName") || ""
//       }`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
//   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
//   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
//   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
//   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
//   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
// ];

// export default function MyDataGrid() {
//   //   const { data } = useDemoData({
//   //     dataSet: "Commodity",
//   //     rowLength: 10,
//   //     maxColumns: 6,
//   //   });

//   const [selectionModel, setSelectionModel] = React.useState([]);

//   return (
//     <div style={{ height: 400, width: "100%" }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         checkboxSelection
//         onSelectionModelChange={(newSelectionModel) => {
//           setSelectionModel(newSelectionModel);
//         }}
//         selectionModel={selectionModel}
//         //{...data}
//       />
//       <pre>{JSON.stringify(selectionModel, null, 4)}</pre>
//     </div>
//   );
// }

//--------------------

// import React from "react";
// //import { DataGrid } from "@material-ui/data-grid";
// import { DataGrid } from "@mui/x-data-grid";

// const columns = [
//   { field: "id", headerName: "ID", width: 70 },
//   { field: "firstName", headerName: "First name", width: 130 },
//   { field: "lastName", headerName: "Last name", width: 130 },
//   {
//     field: "age",
//     headerName: "Age",
//     type: "number",
//     width: 90,
//   },
//   //   {
//   //     field: "fullName",
//   //     headerName: "Full name",
//   //     description: "This column has a value getter and is not sortable.",
//   //     sortable: false,
//   //     width: 160,
//   //     valueGetter: (params) =>
//   //       `${params.getValue("firstName") || ""} ${
//   //         params.getValue("lastName") || ""
//   //       }`,
//   //   },
// ];

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
//   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
//   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
//   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
//   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
//   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
// ];

// export default function MyDataGrid() {
//   const [selectionModel, setSelectionModel] = React.useState([]);
//   return (
//     <div style={{ height: 400, width: "100%" }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         pageSize={25}
//         checkboxSelection
//         hideFooterPagination
//         onSelectionModelChange={(newSelection) => {
//           setSelectionModel(newSelection.selectionModel);
//           console.log(selectionModel);
//         }}
//         // onSelectionModelChange={(ids) => {
//         //           console.log(ids);
//         //         }}

//         selectionModel={selectionModel}
//       />

//       <pre>{JSON.stringify(selectionModel, null, 4)}</pre>
//       {/* {selectionModel.map((val) => (
//         <h1>{val}</h1>
//       ))} */}
//     </div>
//   );
// }

//-----------------------------

// import Button from '@mui/material/Button';
// import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';

// const columns: GridColDef[] = [
//   {
//     field: 'action',
//     headerName: 'Action',
//     sortable: false,
//     renderCell: (params) => {
//       const onClick = (e) => {
//         e.stopPropagation(); // don't select this row after clicking

//         const api: GridApi = params.api;
//         const thisRow: Record<string, GridCellValue> = {};

//         api
//           .getAllColumns()
//           .filter((c) => c.field !== '__check__' && !!c)
//           .forEach(
//             (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
//           );

//         return alert(JSON.stringify(thisRow, null, 4));
//       };

//       return <Button onClick={onClick}>Click</Button>;
//     },
//   },
//   { field: 'id', headerName: 'ID', width: 70 },
//   { field: 'firstName', headerName: 'First name', width: 130 },
//   { field: 'lastName', headerName: 'Last name', width: 130 },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.getValue(params.id, 'firstName') || ''} ${
//         params.getValue(params.id, 'lastName') || ''
//       }`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

// export default function DataGridDemo() {
//   return (
//     <div style={{ height: 400, width: '100%' }}>
//       <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
//     </div>
//   );
// }
